<template>
  <div>
    <a-card title="销售报价创建">
      <div slot="extra" style="margin: -4px 0">
        <a-button ghost icon="left" type="primary" @click="$router.go(-1)">返回</a-button>
      </div>

      <a-spin :spinning="createLoading">
        <a-form-model ref="form" :label-col="{ span: 8 }" :model="formData" :rules="rules" :wrapper-col="{ span: 16 }">
          <a-row>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="销售报价单号" prop="number">
                <a-input v-model="formData.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="客户" prop="client_name">
                <ClientInput v-model="formData.client_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="制单人" prop="handler">
                <UserSelect v-model="formData.handler" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="制单日期" prop="handle_time">
                <a-date-picker v-model="formData.handle_time" valueFormat="YYYY-MM-DD" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="合同单号" prop="contract_number">
                <a-input v-model="formData.contract_number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="交货日期" prop="delivery_date">
                <a-date-picker v-model="formData.delivery_date" valueFormat="YYYY-MM-DD" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="联系人" prop="contact_person">
                <a-input v-model="formData.contact_person" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="联系电话" prop="contact_phone">
                <a-input v-model="formData.contact_phone" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="地址" prop="address">
                <a-input v-model="formData.address" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="备注" prop="remark">
                <a-input v-model="formData.remark" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-item prop="sales_quotation_files" label="附件">
                <SalesQuotationFileUpload v-model="formData.sales_quotation_files" :initialItems="[]" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider orientation="left">物料信息</a-divider>

        <div>
          <div>
            <a-button type="primary" @click="selectModalVisible = true">添加物料</a-button>
          </div>
          <div style="margin-top: 16px">
            <a-table :columns="columns" :dataSource="goodsData" :pagination="false" rowKey="id" size="small">
              <div slot="sales_quantity" slot-scope="value, item, index">
                <a-input-number v-model="item.sales_quantity" size="small" style="width: 100px" />
              </div>
              <div slot="sales_price" slot-scope="value, item, index">
                <a-input-number v-model="item.sales_price" size="small" style="width: 100px" />
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group size="small">
                  <a-button type="danger" @click="removeGoods(item)">移除</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button :loading="createLoading" type="primary">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <SalesInventorySelectModal v-model="selectModalVisible" @select="onSelectGoods" />
  </div>
</template>

<script>
import { salesQuotationOrderNumber, salesQuotationOrderCreate } from "@/api/sale";
import NP from "number-precision";
import moment from "moment";

export default {
  components: {
    ClientInput: () => import("@/components/ClientInput"),
    UserSelect: () => import("@/components/UserSelect"),
    SalesInventorySelectModal: () => import("@/components/SalesInventorySelectModal"),
    SalesQuotationFileUpload: () => import("@/components/SalesQuotationFileUpload"),
  },
  data() {
    return {
      createLoading: false,
      formData: {},
      goodsData: [],

      selectModalVisible: false,
      rules: {
        number: [
          { required: true, message: "请输入销售报价单号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        client_name: [
          { required: true, message: "请输入客户名称", trigger: "change" },
          { max: 64, message: "超出最大长度 (64)", trigger: "change" },
        ],
        handler: [{ required: true, message: "请选择制单人", trigger: "change" }],
        handle_time: [{ required: true, message: "请选择制单时间", trigger: "change" }],
        remark: [{ max: 256, message: "超出最大长度 (256)", trigger: "change" }],
      },
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          fixed: "left",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "物料条码",
          dataIndex: "goods_barcode",
        },
        {
          title: "物料规格",
          dataIndex: "goods_spec",
        },
        {
          title: "物料单位",
          dataIndex: "goods_unit",
        },
        {
          title: "销售数量",
          dataIndex: "sales_quantity",
          scopedSlots: { customRender: "sales_quantity" },
        },
        {
          title: "销售单价",
          dataIndex: "sales_price",
          scopedSlots: { customRender: "sales_price" },
        },
        {
          title: "销售金额(元)",
          dataIndex: "sales_amount",
          customRender: (value, item) => {
            const salesAmount = NP.times(item.sales_quantity ?? 0, item.sales_price ?? 0);
            return NP.round(salesAmount, 2);
          },
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 80,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  computed: {
    userID() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    initData() {
      this.formData = {
        number: "",
        client_name: null,
        handler: this.userID,
        handle_time: moment().format("YYYY-MM-DD"),
        contract_number: null,
        delivery_date: null,
        contact_person: "",
        contact_phone: "",
        address: "",
        remark: "",
        sales_quotation_files: [],
      };
      salesQuotationOrderNumber().then((data) => {
        this.formData.number = data.number;
      });
    },
    onSelectGoods(item) {
      this.goodsData.push({
        goods: item.id,
        goods_number: item.number,
        goods_name: item.name,
        goods_barcode: item.barcode,
        goods_spec: item.spec,
        goods_unit: item.unit_name,
        sales_quantity: 1,
        sales_price: 0,
      });
    },
    removeGoods(item) {
      this.goodsData = this.$functions.removeItem(this.goodsData, item);
    },
    create() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.goodsData.length === 0) {
            this.$message.warning("请选择物料");
            return;
          }

          const formData = {
            ...this.formData,
            sales_quotation_goods_items: this.goodsData.map((item) => ({
              goods: item.goods,
              sales_quantity: item.sales_quantity,
              sales_price: item.sales_price,
            })),
          };

          this.createLoading = true;
          salesQuotationOrderCreate(formData)
            .then((data) => {
              this.$message.success("创建成功");
              this.$router.go(-1);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
